// Base

$primary-color: #007bae;
$background-color: white;
$primary-text-color: black;
$secondary-text-color: white;

* {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  box-sizing: border-box;
  background-color: $background-color;
  font-size: 15px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

h1 {
  color: $primary-text-color;
  letter-spacing: -1px;
  font-size: 95px;
  line-height: 1em;
  font-weight: 700;
}

button {
  background-color: $primary-color;
  border: 1px solid $primary-color;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  color: $secondary-text-color;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 1% 3rem;
  outline: 0;
  height: 48px;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.loader,
.home,
.container {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    width: 15rem;
    height: auto;
    margin: auto;
    &.breathing {
      display:none;
      -webkit-animation: breathing 3s ease-out infinite normal;
      animation: breathing 3s ease-out infinite normal;
    }
  }
}

.page {
  position: absolute;
  width: 100%;
}

.home {
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: $primary-text-color;
  width: 60%;
  img {
    width: 8rem;
    margin: 0 auto 8px;
    display: none;
  }
  h1 {
    margin: 30px auto 0;
  }
  p {
    width: 70%;
    margin: 20px auto;
    line-height: 1.5;
  }
  button {
    margin-top: 20px;
  }
}

.container.form {
  flex-direction: column;
  h1 {
    margin: 1rem 0;
  }
}

.cont-back,
.back {
  position: absolute;
  top: 0;
  right: 0;
  div {
    background-image: url("/assets/back.png");
    width: 100px;
    height: 35px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0) saturate(100%) //black
      invert(20%) sepia(10%) saturate(7283%) hue-rotate(215deg) brightness(87%) contrast(110%); //blue
  }
}

form {
  background-color: #fff;
  font-weight: 400;
  border-radius: 4px;
  padding: 20px 20px;
  letter-spacing: 0;
  box-shadow: 0 0 10px 0 rgba(34, 33, 106, 20%);
  -webkit-box-shadow: 0 0 10px 0 rgba(34, 33, 106, 20%);
  -moz-box-shadow: 0 0 10px 0 rgba(34, 33, 106, 0.2);
  fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 1.5rem;
    justify-content: center;
    align-items: center;
    div {
      width: 90%;
      margin: auto;
    }
    div:nth-child(1) {
      grid-column: 1/2;
    }
    div:nth-child(2) {
      grid-column: 2/3;
    }
    div:nth-child(3) {
      grid-column: 1/2;
    }
    div:nth-child(4) {
      grid-column: 2/3;
    }
    div:nth-child(5) {
      grid-column: 1/3;
    }
    div:nth-child(6) {
      grid-column: 1/2;
    }
    div:nth-child(7) {
      grid-column: 2/3;
      display: flex;
      color: black;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
      }
    }
    div:nth-child(8) {
      grid-column: 1/3;
    }
    div:nth-child(9) {
      grid-column: 1/3;
      button {
        width: 70%;
      }
    }
    span {
      color: red;
    }
  }
}

label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  float: left;
  overflow: hidden;
  padding: 7px 25px 4px 0;
  text-align: left;
}

input[type="text"],
textarea {
  border: 1px solid #cfdadd;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  color: #333;
  font-weight: 100;
  font-size: 14px !important;
  line-height: 20px !important;
  outline: 0;
  background-color: #fff;
  padding: 10px 20px !important;
  font-family: sans-serif;
  box-sizing: border-box;
  box-shadow: 0 0 5px -1px $primary-color inset;
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 8%);
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  margin: 0;
}

textarea {
  border-radius: 20px;
}

input[type="radio"] {
  cursor: pointer;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  margin-right: 7px;
  transform: translateY(5px);
  box-shadow: 0 0 5px -1px $primary-color inset;
}

input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px $primary-color inset;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: $primary-color;
}

.form-loader {
  background-color: #fff;
  font-weight: 400;
  border-radius: 4px;
  padding: 20px 20px;
  letter-spacing: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 20%);
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 20%);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.lds-spinner {
  color: green;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: $primary-color;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

//Animations

// @-webkit-keyframes breathing {
//   0% {
//     -webkit-transform: scale(0.9);
//     transform: scale(0.9);
//   }

//   25% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }

//   60% {
//     -webkit-transform: scale(0.9);
//     transform: scale(0.9);
//   }

//   100% {
//     -webkit-transform: scale(0.9);
//     transform: scale(0.9);
//   }
// }

// @keyframes breathing {
//   0% {
//     -webkit-transform: scale(0.9);
//     -ms-transform: scale(0.9);
//     transform: scale(0.9);
//   }

//   25% {
//     -webkit-transform: scale(1);
//     -ms-transform: scale(1);
//     transform: scale(1);
//   }

//   60% {
//     -webkit-transform: scale(0.9);
//     -ms-transform: scale(0.9);
//     transform: scale(0.9);
//   }

//   100% {
//     -webkit-transform: scale(0.9);
//     -ms-transform: scale(0.9);
//     transform: scale(0.9);
//   }
// }

.page-enter {
  opacity: 0;
  transform: translateX(-400px);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 400ms;
  transition-delay: 600ms;
}
.page-exit {
  opacity: 1;
  transform: translateX(0px);
}
.page-exit-active {
  opacity: 0;
  transition: opacity 400ms;
  transform: translateX(-400px);
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
// Responsive

$medium: 1440px;
@media (max-width: $medium) {
  .content {
    width: 80%;
  }
  .form h1 {
    margin: 3rem auto;
  }
}

$small: 1024px;
@media (max-width: $small) {
  .content {
    width: 90%;
    h1 {
      margin: 0;
    }
  }
  .form {
    .back > div {
      background-image: url("/assets/b-arr.png");
      width: 50px;
      height: 25px;
    }
  }
}

$tablet: 768px;
@media (max-width: $tablet) {
  .container {
    margin-bottom: 3rem;
  }
  h1 {
    font-size: 55px;
  }
  .form {
    fieldset > * {
      grid-column: 1/3 !important;
    }
    .back > div {
      background-image: url("/assets/b-arr.png");
      width: 40px;
      height: 20px;
    }
  }
}

$mobil: 425px;
@media (max-width: $mobil) {
  .form {
    h1 {
      font-size: 35px;
    }
    .back > div {
      background-image: url("/assets/b-arr.png");
      width: 30px;
      height: 15px;
    }
  }
}
